import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import '../scss/styles.scss';

export default class extends React.Component {
  render() {
    return (
      <div className={'ie-page'}>
        <Helmet>
          <html lang="en" />
          <title>Norsepower</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <main style={{display: 'flex', 'justifyContent': 'center', alignItems: 'Center', height: '100vh'}}>
          <h1 style={{maxWidth: '720px', textAlign: 'center'}}>
              Unfortunately the site does not support Internet Explorer since it is deprecated and might contain security vulnerabilities. Please consider changing to a modern browser like Edge, Chrome or Firefox.
          </h1>
        </main>
      </div>
    );
  }
}

export const pageQuery = graphql`
  query PageIEQuery {
    contentfulPage(slug: { eq: "404" }) {
      title
    }
  }
`;
